import { useNav } from "../hooks/useNav"
import insta from "../resources/Insta.jpg"
import tiktok from "../resources/Tiktok.jpg"
import shoulderPress from "../resources/shoulderPress.png"
import bicepCurl from "../resources/bicepCurl2.png"
import preacherCurl from "../resources/preacherCurl.png"
import pullUp from "../resources/pullUp.png"


const ContactMe = () => {
  const flightRef = useNav("Contact")

  let screenWidth = window.innerWidth;

  let numberOfImages = () => {

    if (screenWidth > 1400) {
      return 4
    } else if (screenWidth > 1100) {
      return 3
    } else if (screenWidth > 650) {
      return 2
    } else {
      return 1
    }
  }

  let images: any[] = [shoulderPress, preacherCurl, bicepCurl, pullUp]

  let renderImages = () => {

    let totalImages: number = numberOfImages()

    return (

      <>

        {
          images.map((image: any, index: number) => {

            if (index < totalImages) {
              return <img src={image} alt={`ContactMeImage_${index}`} style={{ width: 'auto', height: '60vh', margin: '10px' }} />
            }
          }
          )
        }

      </>
    )

  }



  return (
    <section ref={flightRef} id="contactSection" style={{ paddingTop: "100px", paddingBottom: '50px' }} >

      <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
        <h2 style={{}} data-aos='fade-up' className="bebas-neue-regular">Contact Me</h2>
      </div>


      <div data-aos='fade-up' style={{ display: 'flex', justifyContent: "space-around", flexWrap: 'nowrap', overflow: 'hidden', overflowY: 'hidden' }}>

        {
          renderImages()
        }


        {/* <img src={shoulderPress} alt="Shoulder Press" style={{ width: 'auto', height: '60vh', margin: '10px' }} />
        <img src={preacherCurl} alt="Preacher Curl" style={{ width: 'auto', height: '60vh', margin: '10px' }} />
        <img src={bicepCurl} alt="Bicep Curl" style={{ width: 'auto', height: '60vh', margin: '10px' }} />
        <img src={pullUp} alt="Pull Up" style={{ width: 'auto', height: '60vh', margin: '10px' }} /> */}

      </div>

      <p style={{ padding: '15px', paddingTop: '20px', paddingBottom: '20px', fontSize: '20px', textAlign: 'center' }}>

        If you have any questions or would like to book a session, please contact me via Email or by Soical Media.
        <br></br><br></br>
        <b>Email:</b> bencressallfitness@outlook.com

      </p>



      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a href="https://www.instagram.com/bencressall.fit/">
          <img src={insta} alt="Instagram" style={{ width: '110px', height: '100px', margin: '10px' }} />
        </a>

        <a href="https://www.tiktok.com/@bencressall.fit">
          <img src={tiktok} alt="Tiktok" style={{ width: '110px', height: '100px', margin: '10px' }} />
        </a>

      </div>
    </section>
  )
}

export default ContactMe