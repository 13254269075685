import { useContext, useState } from 'react';
import { NavContext } from '../context/NavContext';
import styles from '../styles/Header.module.css'
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Drawer, Icon, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
// import zIndex from '@mui/material/styles/zIndex';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import PersonIcon from '@mui/icons-material/Person';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

const Header = () => {
  const { activeLinkId } = useContext(NavContext);

  const [color, setColor] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const changeHeaderColor = () => {

    //calculate the height of the window
    let windowHeight = window.innerHeight;

    if (window.scrollY >= windowHeight - 150) {
      setColor(true);
    } else {
      setColor(false);
    }
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  }

  window.addEventListener('scroll', changeHeaderColor);

  //get the screen with device
  let screenWidth = window.innerWidth;

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation">
      <List>

        <ListItem key={"About Me"} disablePadding>
          <ListItemButton onClick={() => handleClickNav('About Me')}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={"About Me"} />
          </ListItemButton>
        </ListItem>


        <ListItem key={"Program"} disablePadding>
          <ListItemButton onClick={() => handleClickNav('Program')}>
            <ListItemIcon>
              <FitnessCenterIcon />
            </ListItemIcon>
            <ListItemText primary={"Program"} />
          </ListItemButton>
        </ListItem>


        <ListItem key={"App"} disablePadding>
          <ListItemButton onClick={() => handleClickNav('App')}>
            <ListItemIcon>
              <SmartphoneIcon />
            </ListItemIcon>
            <ListItemText primary={"App"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"Contact"} disablePadding>
          <ListItemButton onClick={() => handleClickNav('Contact')}>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary={"Contact"} />
          </ListItemButton>
        </ListItem>


      </List>
      <Divider />
    </Box>
  );


  const navLinks = ["About Me", "Program", "App", "Contact"];

  const handleClickLogo = () => {
    document.getElementById("homeSection")?.scrollIntoView({ behavior: "smooth" })
  }

  const handleClickNav = (content: any) => {
    const scrollToId = `${content.toLowerCase()}Section`;

    document.getElementById(scrollToId)?.scrollIntoView({ behavior: "smooth" })
  }

  const renderNavLink = (content: any) => {
    const scrollToId = `${content.toLowerCase()}Section`;

    const handleClickNav = () => {
      document.getElementById(scrollToId)?.scrollIntoView({ behavior: "smooth" })
    }

    return (
      <ul key={content}>
        <li>
          <button
            onClick={handleClickNav}
            className={activeLinkId === content ? styles.activeClass : ""}
          >{content}</button>
        </li>
      </ul>
    )
  }

  return (
    <header className={color ? styles.headerScroll : styles.header} style={{ zIndex: '1000', maxWidth: '100%' }}>
      <div className={styles.container}>
        <div className={styles.navContainer}>
          <h1 onClick={handleClickLogo} className='bebas-neue-regular'>Ben Cressall Fitness</h1>


          {screenWidth < 650 ?
            <>
              <Drawer open={drawerOpen} anchor='right' onClose={handleDrawerOpen}>
                <div style={{ width: '200px' }}>
                  {DrawerList}
                </div>
              </Drawer>
              <IconButton onClick={handleDrawerOpen}>
                <MenuIcon style={{ fontSize: '2.5rem' }}></MenuIcon>
              </IconButton>
            </>
            : <>
              <div>
                <nav>
                  {navLinks.map(nav => renderNavLink(nav))}
                </nav>
              </div>
            </>
          }

        </div>
      </div>
    </header>
  )
}

export default Header