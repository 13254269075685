import React, { useLayoutEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from "./components/Header";
import AboutMe from "./sections/AboutMe";
import Programs from "./sections/Program";
import ContactMe from "./sections/ContactMe";
import Home from "./sections/Home";
import AppInfo from "./sections/AppInfo";
import NavProvider from './context/NavContext'

function App() {

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  return (
    <div style={{ maxWidth: '100%' }}>
      <NavProvider>
        <Header />
        <Home />
        <AboutMe />
        <Programs />
        <AppInfo />
        <ContactMe />

      </NavProvider>
    </div>
  );
}

export default App;
