import React, { useEffect } from "react"
import { useNav } from "../hooks/useNav"
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Aos from "aos";
import "aos/dist/aos.css";
// import zIndex from "@mui/material/styles/zIndex";
import mainpage from "../resources/aboutme.jpg"
import lightTexture from "../resources/gray2Texture.jpg"

const AboutMe = () => {
    const bookingRef = useNav("About Me")

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    let screenWidth = window.innerWidth;

    return (
        <>

            <section ref={bookingRef} id="about meSection" className="secondary-section" style={{ maxWidth: '100%', overflow: 'hidden', paddingTop: '100px', paddingBottom: '50px' }}>

                {screenWidth > 900 ?

                    <>
                        <Grid container spacing={1} sx={{ zIndex: '2' }}>

                            <Grid xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '-100' }}>

                                <div data-aos='fade-right' style={{ marginRight: '0px', margin: '5px' }}>
                                    <img src={mainpage} alt="Ben"
                                        style={{ position: 'relative', width: 'auto', height: '80vh' }} />
                                </div>

                            </Grid>

                            <Grid xs={6}>

                                <div>

                                    <Grid container spacing={1}>

                                        <Grid xs={12} >
                                            <h2 style={{ paddingLeft: '70px' }} data-aos='fade-left' className="bebas-neue-regular">About Me</h2>
                                        </Grid>


                                        <Grid xs={12} >
                                            <div style={{ maxWidth: '750px', paddingLeft: '70px', paddingRight: '50px' }}>
                                                <p data-aos='fade-left' style={{ fontSize: '17px', textAlign: 'justify' }}>

                                                    <br></br><br></br>

                                                    Fitness is a life long journey ... its a marathon, not a sprint.
                                                    It's about learning about your body and what works for you.
                                                    As a personal trainer with lots of experience, my goal is to give you all my knowledge
                                                    and help you avoid common mistakes that can hold your progress back for months or even years. <br></br><br></br>

                                                    As someone who works full time as an Engineer, part time at Bunnings and has a family,
                                                    I know that life can get busy and looking after our own health is often the first thing that goes
                                                    out the window when the stress of life piles up.<br></br><br></br>

                                                    I work with all my clients to give them the knowledge to build sustainable fitness programs that
                                                    integrates with their lives, not becomes it. I'll never just give you a copy and paste solution and hold your hand
                                                    until our time together is done.<br></br><br></br>

                                                    At the end of the day, I can't lift the weights for you, but I can give you the tools and support
                                                    to do it yourself in an optimal way. <br></br><br></br>

                                                    If you're looking to lose weight or bulk up and want a solid game plan to get you started, I'd love to hear from you. <br></br><br></br>

                                                    Cheers, <br></br>Ben

                                                </p>
                                            </div>

                                        </Grid>

                                    </Grid>

                                </div>

                            </Grid>



                        </Grid>
                    </>

                    :

                    <>
                        <Grid container justifyContent={'center'} spacing={1} sx={{ zIndex: '2' }}>



                            <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <h2 data-aos='fade-up' className="bebas-neue-regular">About Me</h2>
                            </Grid>


                            <Grid xs={12} >
                                <div style={{ maxWidth: '800px', paddingLeft: '20px', paddingRight: '20px' }}>
                                    <p data-aos='fade-up' style={{ textAlign: 'justify' }}> <br></br><br></br>

                                        Fitness is a life long journey ... its a marathon, not a sprint.
                                        It's about learning about your body and what works for you.
                                        As a personal trainer with lots of experience, my goal is to give you all my knowledge
                                        and help you avoid common mistakes that can hold your progress back for months or even years. <br></br><br></br>

                                        As someone who works full time as an Engineer, part time at Bunnings and has a family,
                                        I know that life can get busy and looking after our own health is often the first thing that goes
                                        out the window when the stress of life piles up.<br></br><br></br>

                                        I work with all my clients to give them the knowledge to build sustainable fitness programs that
                                        integrates with their lives, not becomes it. I'll never just give you a copy and paste solution and hold your hand
                                        until our time together is done.<br></br><br></br>

                                        At the end of the day, I can't lift the weights for you, but I can give you the tools and support
                                        to do it yourself in an optimal way. <br></br><br></br>

                                        If you're looking to lose weight or bulk up and want a solid game plan to get you started, I'd love to hear from you. <br></br><br></br>

                                        Cheers, <br></br> Ben
                                    </p>
                                </div>

                            </Grid>

                            <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '-100' }}>

                                <div data-aos='fade-up' style={{ marginRight: '0px', margin: '5px' }}>
                                    <img src={mainpage} alt="Ben"
                                        style={{ position: 'relative', width: 'auto', height: '60vh' }} />
                                </div>

                            </Grid>









                        </Grid>
                    </>

                }











            </section >
        </>
    )
}

export default AboutMe