import React, { useEffect } from "react"
import { useNav } from "../hooks/useNav"
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Aos from "aos";
import "aos/dist/aos.css";
// import zIndex from "@mui/material/styles/zIndex";
import mainpage from "../resources/mainpage.png"
import nutritionTracking from "../resources/nutritionTracking.png"
import individual from "../resources/individualExercise.png"

const AppInfo = () => {
    const hotelRef = useNav("App")
    let screenWidth = window.innerWidth;

    return (
        <section ref={hotelRef} id="appSection" className="secondary-section" style={{ paddingTop: "100px", paddingBottom: '50px' }}>

            {screenWidth > 1200 ?

                <>

                    <Grid container spacing={0} sx={{ width: '100%', zIndex: '2' }}>

                        <Grid data-aos='fade-right' xs={12} md={7} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', zIndex: '-100' }}>

                            <div style={{ marginRight: '0px', margin: '5px' }}>
                                <img src={mainpage} alt="Ben"
                                    style={{ position: 'relative', width: 'auto', height: '70vh' }} />
                            </div>
                            <div style={{ marginRight: '0px', margin: '5px' }}>
                                <img src={nutritionTracking} alt="Ben"
                                    style={{ position: 'relative', width: 'auto', height: '70vh' }} />
                            </div>
                            <div style={{ margin: '5px' }}>
                                <img src={individual} alt="Ben"
                                    style={{ position: 'relative', width: 'auto', height: '70vh' }} />
                            </div>
                        </Grid>


                        <Grid xs={12} md={5}>

                            <div>

                                <Grid container spacing={1}>

                                    <Grid xs={10} md={10} lg={10} >
                                        <h2 data-aos='fade-left' className="bebas-neue-regular" style={{ fontSize: '3rem', paddingLeft: '15px' }}>The Ultimate Fitness Tracker</h2>
                                    </Grid>

                                    <Grid xs={1} md={1} lg={1} >

                                    </Grid>

                                    <Grid xs={1} md={1} lg={1} >

                                    </Grid>


                                    <Grid xs={10} md={10} lg={10} style={{ display: 'flex', justifyContent: 'center' }} >
                                        <p data-aos='fade-left' style={{ paddingLeft: '15px', textAlign: 'justify' }}>

                                            The best way to make progress in the gym is to progressively overload your muscles each session. This means that every time
                                            we go we should aim to add more weigh or squeeze out one or two more reps. <br></br><br></br>

                                            The only way to do this properly is to log our exercises and to try and beat our previous attempts. <br></br><br></br>

                                            The same goes for nutrition. If we want to lose weight or gain muscle, we need to track our food intake and there is just
                                            no getting around it. <br></br><br></br>

                                            All my clients get access to my exclusive app which I have personally built from the ground up and gives you all the tools
                                            you need to get fit. Easy to use features in the app include: <br></br><br></br>

                                            <ul style={{ marginLeft: '25px', textAlign: 'left' }}>

                                                <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Log every set, rep and weight for individual exercises and workouts</li>
                                                <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Record and track your body measurements</li>
                                                <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Log daily habits</li>
                                                <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Track your macronutrient and calorie intake</li>

                                            </ul><br></br>

                                            You can make using the app as simple or as complicated as you like. It has simple features for beginners to just track your weights,
                                            and more advanced features such as looking at sets and total volume per body part per week.


                                        </p>
                                    </Grid>

                                </Grid>

                            </div>

                        </Grid>




                    </Grid>

                </>

                :

                <>

                    <Grid container spacing={0} sx={{ width: '100%', zIndex: '2' }}>

                        <Grid xs={12} >

                            <div>

                                <Grid container spacing={0} justifyContent={'center'}>

                                    <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }} >
                                        <h2 data-aos='fade-up' className="bebas-neue-regular" style={{ fontSize: '3rem', paddingLeft: '15px', textAlign: 'center' }}>The Ultimate Fitness Tracker</h2>
                                    </Grid>




                                    <Grid xs={10} md={10} lg={10} style={{ display: 'flex', justifyContent: 'center' }} >
                                        <p data-aos='fade-up' style={{ paddingLeft: '5px', paddingRight: '5px', textAlign: 'justify' }}>

                                            The best way to make progress in the gym is to progressively overload your muscles each session. This means that every time
                                            we go we should aim to add more weigh or squeeze out one or two more reps. <br></br><br></br>

                                            The only way to do this properly is to log our exercises and to try and beat our previous attempts. <br></br><br></br>

                                            The same goes for nutrition. If we want to lose weight or gain muscle, we need to track our food intake and there is just
                                            no getting around it. <br></br><br></br>

                                            All my clients get access to my exclusive app which I have personally built from the ground up and gives you all the tools
                                            you need to get fit. Easy to use features in the app include: <br></br><br></br>

                                            <ul style={{ marginLeft: '15px', textAlign: 'left' }}>

                                                <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Log every set, rep and weight for individual exercises and workouts</li>
                                                <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Record and track your body measurements</li>
                                                <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Log daily habits</li>
                                                <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Track your macronutrient and calorie intake</li>

                                            </ul><br></br>

                                            You can make using the app as simple or as complicated as you like. It has simple features for beginners to just track your weights,
                                            and more advanced features such as looking at sets and total volume per body part per week.


                                        </p>
                                    </Grid>

                                </Grid>

                            </div>

                        </Grid>

                        <Grid data-aos='fade-up' xs={12} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', zIndex: '-100' }}>

                            <div style={{ marginRight: '0px', margin: '5px' }}>
                                <img src={mainpage} alt="Ben"
                                    style={{ position: 'relative', width: 'auto', height: '70vh' }} />
                            </div>
                            <div style={{ marginRight: '0px', margin: '5px' }}>
                                <img src={nutritionTracking} alt="Ben"
                                    style={{ position: 'relative', width: 'auto', height: '70vh' }} />
                            </div>
                            <div style={{ margin: '5px' }}>
                                <img src={individual} alt="Ben"
                                    style={{ position: 'relative', width: 'auto', height: '70vh' }} />
                            </div>
                        </Grid>




                    </Grid>

                </>



            }


        </section>
    )
}

export default AppInfo