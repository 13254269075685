import { useNav } from "../hooks/useNav"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../index.css";
import background from "../resources/dark-texture-watercolor.jpg"
import downArrow from "../resources/arrow_down.png"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true
};

const Home = () => {
    const homeRef = useNav("Home")

    //the the screen height `${screenHeight}px`
    const screenHeight = window.innerHeight;
    let screenWidth = window.innerWidth;

    const handleClickLogo = () => {
        document.getElementById("about meSection")?.scrollIntoView({ behavior: "smooth" })
    }

    return (

        <>
            <section ref={homeRef} id="homeSection" style={{ 'backgroundColor': '#434142', maxWidth: '100%' }}>

                {screenWidth > 650 ?



                    <div style={{ backgroundImage: `url(${background})`, backgroundSize: '100% 100%', width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>

                        <div className="box" style={{ zIndex: '1' }}>
                            {screenWidth < 650 ?
                                <h2 style={{ zIndex: '1', color: 'white', fontFamily: 'Bebas Neue, sans-serif', fontSize: '10vw', fontWeight: 'normal' }}>
                                    Build a body to be proud of</h2>
                                :
                                <h2 style={{ zIndex: '1', color: 'white', fontFamily: 'Bebas Neue, sans-serif', fontSize: '7vw', fontWeight: 'normal' }}>
                                    Build a body to be proud of</h2>}

                        </div>
                        <div style={{ position: 'absolute', bottom: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                            <div style={{ color: 'white', fontFamily: 'Bebas Neue, sans-serif', fontSize: '1.5rem', fontWeight: 'normal' }}>
                                Discover Below
                            </div>

                            <div>
                                <button style={{ backgroundColor: 'rgba(0,0,0,0)', border: 'none' }} onClick={handleClickLogo}>
                                    <img src={downArrow} alt="down arrow" style={{ width: '50px', height: '50px', zIndex: '1' }} />
                                </button>
                            </div>
                        </div>


                    </div>

                    :
                    <div style={{ backgroundImage: `url(${background})`, backgroundSize: '200% 100%', width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>

                        <div className="box" style={{ zIndex: '1' }}>
                            {screenWidth < 650 ?
                                <h2 style={{ zIndex: '1', color: 'white', fontFamily: 'Bebas Neue, sans-serif', fontSize: '10vw', fontWeight: 'normal' }}>
                                    Build a body to be proud of</h2>
                                :
                                <h2 style={{ zIndex: '1', color: 'white', fontFamily: 'Bebas Neue, sans-serif', fontSize: '7vw', fontWeight: 'normal' }}>
                                    Build a body to be proud of</h2>}

                        </div>
                        <div style={{ position: 'absolute', bottom: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                            <div style={{ color: 'white', fontFamily: 'Bebas Neue, sans-serif', fontSize: '1.5rem', fontWeight: 'normal' }}>
                                Discover Below
                            </div>

                            <div>
                                <button style={{ backgroundColor: 'rgba(0,0,0,0)', border: 'none' }} onClick={handleClickLogo}>
                                    <img src={downArrow} alt="down arrow" style={{ width: '50px', height: '50px', zIndex: '1' }} />
                                </button>
                            </div>
                        </div>


                    </div>

                }


                {/* </div>

                <img src={background} alt="Ben"
                    style={{ position: 'relative', width: '100%', height: '100vh' }} />

                <h2 className="bebas-neue-regular" style={{ position: 'absolute', bottom: '50%', left: '10%', zIndex: '1', color: 'white' }}> 
                Ben Cressall <br></br> Fitness</h2>

                <div className="box" style={{ position: 'absolute', top: '30%', left: '10%', zIndex: '1' }}>

                    <h2 style={{ zIndex: '1', color: 'white' }}>
                        Build a body you can be proud of</h2>

                </div> */}





                {/* <div style={{ border: 'solid re 1px', height: '100%', width: '100%', paddingTop: '0px' }}>
                    <Slider {...settings}>
                        <div >
                            <div style={{ height: 'calc(100vh - 0px)', border: 'green solid 1px', backgroundColor: 'red', zIndex: '-10', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                
                                <h1 className="bebas-neue-regular" style={{ color: 'white', fontSize: '7.5rem' }}>Ben Cressall Fitness</h1>
                            </div>
                        </div >
                        <div  >
                            <div style={{ height: 'calc(100vh - 0px)', border: 'green solid 1px', backgroundColor: 'green', zIndex: '-10', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <h1 className="bebas-neue-regular" style={{ color: 'white', fontSize: '7.5rem' }}>Ben Cressall Fitness</h1>
                            </div>                        </div>
                        <div >
                            <div style={{ height: 'calc(100vh - 0px)', border: 'green solid 1px', backgroundColor: 'blue', zIndex: '-10', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <h1 className="bebas-neue-regular" style={{ color: 'white', fontSize: '7.5rem' }}>Ben Cressall Fitness</h1>
                            </div>                        </div>

                    </Slider>


                </div> */}




                {/* <div className="triangle" style={{ position: 'absolute', bottom: '0px', left: '20%' }}> </div> */}
                {/* <div style={{ position: 'absolute', bottom: '0px', left: '60%', zIndex: '-10' }}> </div> */}

                {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic optio dolorum exercitationem, harum repellat explicabo quis error excepturi porro soluta.</p> */}
            </section>



        </>
    )
}

export default Home