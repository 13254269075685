import React, { useEffect } from "react"
import { useNav } from "../hooks/useNav"
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Aos from "aos";
import "aos/dist/aos.css";
import mainpage from "../resources/leg_raise.png"
// import zIndex from "@mui/material/styles/zIndex";
const Programs = () => {
    const destinationRef = useNav("Program")

    let screenWidth = window.innerWidth;

    let boxHeight = '580px'


    const programInfo = () => {

        return (

            <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', paddingTop: '50px' }}>

                <div style={{ border: 'solid 0px red', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>

                    <div data-aos='fade-up' style={{ margin: '25px', display: 'flex', justifyContent: 'center', zIndex: '-2', border: 'solid black 3px', borderRadius: '20px', width: '300px', minHeight: boxHeight }}>

                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingBottom: '30px' }}>

                            <div>
                                <div style={{ textDecoration: 'underline', fontFamily: 'Bebas Neue, sans-serif', fontSize: '40px', fontWeight: 'normal', display: 'flex', justifyContent: 'center' }}>
                                    Online Coaching
                                </div>
                                <div style={{ padding: '15px', fontSize: '15px', textAlign: 'justify', }}>

                                    Unlock your potential with my full online coaching program. Have access to me 24/7 and we will work together to reach your fitness goals. <br></br><br></br>

                                    Clients who subscribe to the program get access to: <br></br><br></br>

                                    <ul style={{ marginLeft: '15px', textAlign: 'left' }}>
                                        <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Custom 12 week workout plans with unlimited updates.</li>
                                        <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Free exercise form reviews and round the clock support.</li>
                                        <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Nutritional advice and goal setting.</li>
                                        <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Access to my exclusive app to track exercise progression, body measurements and nutritional intake.</li>
                                        <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> 2x Weekly online check-ins and progress meetings (30 min each)</li>

                                    </ul>

                                </div>

                            </div>


                            <div style={{ textDecoration: 'underline', fontFamily: 'Bebas Neue, sans-serif', fontSize: '40px', fontWeight: 'normal', display: 'flex', justifyContent: 'center' }}>
                                $60 per week
                            </div>




                        </div>
                    </div>

                    <div data-aos='fade-up' style={{ margin: '25px', display: 'flex', justifyContent: 'center', zIndex: '-2', border: 'solid black 3px', borderRadius: '20px', width: '300px', minHeight: boxHeight }}>

                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingBottom: '30px' }}>

                            <div>
                                <div style={{ textDecoration: 'underline', fontFamily: 'Bebas Neue, sans-serif', fontSize: '40px', fontWeight: 'normal', display: 'flex', justifyContent: 'center' }}>
                                    App Only
                                </div>
                                <div style={{ padding: '15px', fontSize: '15px', textAlign: 'justify' }}>

                                    Get access to my exclusive app and track your progress on your own. <br></br><br></br>

                                    Forget logging you sets and reps on paper, my app offers the convenience of having all your information in one place with built in analytics to track progress at a glance. <br></br><br></br>

                                    Clients who subscribe to the app get access to: <br></br><br></br>

                                    <ul style={{ marginLeft: '15px', textAlign: 'left' }}>
                                        <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> 1x Custom 12 week workout plan</li>
                                        <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> 20+ Tried and Tested workout programs with the ability to create your own and customise to your needs</li>
                                        <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Nutritional tracking</li>
                                        <li style={{ marginBottom: '5px', listStyleType: 'disc' }}> Body Measurement / Habit Tracking</li>

                                    </ul>

                                </div>
                            </div>
                            <div style={{ textDecoration: 'underline', fontFamily: 'Bebas Neue, sans-serif', fontSize: '40px', fontWeight: 'normal', display: 'flex', justifyContent: 'center' }}>
                                $5 per week
                            </div>




                        </div>
                    </div>

                </div>
                <div style={{ border: 'solid 0px red', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>

                    <div data-aos='fade-up' style={{ margin: '25px', display: 'flex', justifyContent: 'center', zIndex: '-2', border: 'solid black 3px', borderRadius: '20px', width: '300px', minHeight: boxHeight }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingBottom: '30px' }}>

                            <div>
                                <div style={{ textDecoration: 'underline', fontFamily: 'Bebas Neue, sans-serif', fontSize: '40px', fontWeight: 'normal', display: 'flex', justifyContent: 'center' }}>
                                    PT Session
                                </div>
                                <div style={{ padding: '15px', fontSize: '15px', textAlign: 'justify' }}>

                                    If your lacking motivation and need some accountability and guidance, a one-on-one PT is session for you. <br></br><br></br>

                                    Join me for a 1-hour session where we will workout together to not only get a great pump, but learn new lifting techniques to take your workouts to the next level. <br></br><br></br>

                                    I offer a range of different training styles from resistance training for muscle growth, to high intensity interval training (HIIT) for fat loss. <br></br><br></br>

                                    Whether you're a beginner looking for a solid foundation or an experienced lifter looking to break through a plateau, I can help you get there. <br></br><br></br>

                                </div>
                            </div>

                            <div style={{ textDecoration: 'underline', fontFamily: 'Bebas Neue, sans-serif', fontSize: '40px', fontWeight: 'normal', display: 'flex', justifyContent: 'center' }}>
                                $50 per session
                            </div>


                        </div>
                    </div>

                    <div data-aos='fade-up' style={{ margin: '25px', display: 'flex', justifyContent: 'center', zIndex: '-2', border: 'solid black 3px', borderRadius: '20px', width: '300px', minHeight: boxHeight }}>

                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingBottom: '30px' }}>

                            <div>
                                <div style={{ textDecoration: 'underline', fontFamily: 'Bebas Neue, sans-serif', fontSize: '40px', fontWeight: 'normal', display: 'flex', justifyContent: 'center' }}>
                                    Group Session
                                </div>
                                <div style={{ padding: '15px', fontSize: '15px', textAlign: 'justify' }}>

                                    Split the bill and get fit together! <br></br><br></br>

                                    Group training can be a great way to stay motivated and have fun while working out. <br></br><br></br>

                                    I offer group training sessions for up to 4 people. They typically last 45min - 1 hour and you will walk away feeling on top of the world.  <br></br><br></br>

                                    These sessions can be circuit, HIIT or resistance training based on the groups goals. <br></br><br></br>

                                    I run group sessions on a weekly basis and can cater to all fitness levels. If you would like to join a group please reach out <br></br><br></br>

                                </div>
                            </div>

                            <div style={{ textDecoration: 'underline', fontFamily: 'Bebas Neue, sans-serif', fontSize: '40px', fontWeight: 'normal', display: 'flex', justifyContent: 'center' }}>
                                $20 per person
                            </div>


                        </div>
                    </div>

                </div>



            </Grid>
        )
    }

    return (
        <section ref={destinationRef} id="programSection" style={{ paddingTop: "100px", paddingBottom: '50px', border: "green solid 0px" }} >

            {screenWidth > 900 ?

                <>

                    <Grid container spacing={1} style={{ border: "red solid 0px" }}>

                        <Grid md={1}></Grid>

                        <Grid md={6}>

                            <div>

                                <Grid container spacing={1} style={{ border: "blue solid 0px" }}>


                                    <Grid xs={12} style={{ display: 'flex', paddingLeft: '50px', justifyContent: 'Left', border: "purple solid 0px" }}  >
                                        <h2 data-aos='fade-right' className="bebas-neue-regular">Your Program</h2>
                                    </Grid>

                                    <Grid xs={12}  >
                                        <p data-aos='fade-right' style={{ maxWidth: '800px', paddingLeft: '50px', paddingRight: '10px', border: "purple solid 0px", fontSize: '17px', textAlign: 'justify' }}>

                                            Training doesn't need to be complicated. It's about having a solid game plan and being consistent.  <br></br><br></br>

                                            The fitness industry is full of fads and misinformation promising quick results. The reality is that it takes a good 2-3 months
                                            of hard work before you can see noticeable results in the mirror. And even then this is just the beginning! Fitness is a life long
                                            pursuit and can be an enjoyable journey when done right. <br></br><br></br>

                                            When you work with me, my number one goal is to give you the knowledge and tools to be able to train on your own for years to come.
                                            I will never leave you wondering why we are doing a certain exercise or how to do it. <br></br><br></br>

                                            This means that when your time with me is done, not only will you have a great body and healthy mind to show for it, but will have the confidence to walk
                                            into any gym and know exactly what to do. <br></br><br></br>

                                            I build all my programs based on scientific principles and tried and tested methods. I'll work with you to create a program that is tailored to your goals and lifestyle. <br></br><br></br>

                                        </p>
                                    </Grid>

                                </Grid>

                            </div>

                        </Grid>

                        <Grid md={5} style={{ display: 'flex', justifyContent: 'center', border: "blue solid 0px" }}>

                            <div data-aos='fade-left' style={{ marginLeft: '50px', margin: '5px' }}>
                                <img src={mainpage} alt="Ben"
                                    style={{ width: 'auto', height: '70vh' }} />
                            </div>

                        </Grid>

                        {programInfo()}



                    </Grid>

                </>

                :

                <>

                    <Grid container spacing={1} style={{ border: "red solid 0px" }}>

                        <Grid xs={12} style={{ display: 'flex', justifyContent: 'center', border: "purple solid 0px" }}  >
                            <h2 data-aos='fade-up' className="bebas-neue-regular" style={{ fontSize: '75px', textAlign: 'center' }}>Your Program</h2>
                        </Grid>

                        <Grid xs={12}  >
                            <p data-aos='fade-up' style={{ maxWidth: '800px', paddingLeft: '20px', paddingRight: '20px', border: "purple solid 0px", textAlign: 'justify' }}>

                                Training doesn't need to be complicated. It's about having a solid game plan and being consistent.  <br></br><br></br>

                                The fitness industry is full of fads and misinformation promising quick results. The reality is that it takes a good 2-3 months
                                of hard work before you can see noticeable results in the mirror. And even then this is just the beginning! Fitness is a life long
                                pursuit and can be an enjoyable journey when done right. <br></br><br></br>

                                When you work with me, my number one goal is to give you the knowledge and tools to be able to train on your own for years to come.
                                I will never leave you wondering why we are doing a certain exercise or how to do it. <br></br><br></br>

                                This means that when your time with me is done, not only will you have a great body and healthy mind to show for it, but will have the confidence to walk
                                into any gym and know exactly what to do. <br></br><br></br>

                                I build all my programs based on scientific principles and tried and tested methods. I'll work with you to create a program that is tailored to your goals and lifestyle. <br></br><br></br>

                            </p>

                        </Grid>

                        <Grid xs={12} style={{ display: 'flex', justifyContent: 'center', border: "blue solid 0px" }}>

                            <div data-aos='fade-up' style={{ marginLeft: '50px', margin: '5px' }}>
                                <img src={mainpage} alt="Ben"
                                    style={{ width: 'auto', height: '80vh' }} />
                            </div>

                        </Grid>



                        {programInfo()}

                    </Grid>



                </>

            }





        </section >
    )
}

export default Programs